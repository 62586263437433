<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Winkelmandje" />
    <h1>Winkelmandje</h1>
    <b-alert
      v-if="(retrievalTimes.length < 1 || returnTimes.length < 1) && !waitingForProcesses"
      show
      variant="warning"
    >
      <font-awesome-icon icon="exclamation-circle" />Opgelet: Het lijkt er op
      dat een artikel niet opgehaald of terug gebracht kan worden in de
      geselecteerde uitleenperiode
    </b-alert>
    <b-alert v-if="addedReservationItems" show variant="info">
      <font-awesome-icon icon="exclamation-circle" />Opgelet: Artikelen van de
      reservering zijn toegevoegd aan je winkelmandje.
    </b-alert>
    <div v-if="paAttestRequired || riscoAnalyseRequired">
      <b-alert v-if="paAttestRequired" show variant="warning">
        <font-awesome-icon icon="exclamation-circle" />Opgelet: PA-attest is
        vereist voor een product in je winkelmandje!
      </b-alert>
      <b-alert v-if="riscoAnalyseRequired" show variant="warning">
        <font-awesome-icon icon="exclamation-circle" />Opgelet: Een risico
        analyse is vereist voor een product in je winkelmandje!
      </b-alert>
    </div>
    <b-alert v-if="warning" show variant="warning">
      <font-awesome-icon icon="exclamation-circle" />
      Opgelet: {{ warning }}
    </b-alert>
    <b-form
      v-if="
        this.$store.getters.cartCount > 1 ||
          (!adminCosts && this.$store.getters.cartCount > 0)
      "
      @submit.prevent="updateCartAndContinue"
    >
      <b-card class="mb-4">
        <b-row>
          <b-col md="12" lg="12" xl="2">
            <b-form-group
              id="input-group-1"
              label="Afhaallocatie"
              label-for="input-warehouse"
            >
              <b-form-select
                id="input-warehouse"
                v-model="warehouse"
                :options="warehouses"
                :disabled="waitingForProcesses"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" lg="12" xl="4">
            <b-row>
              <b-col xs="12" md="6">
                <b-form-group
                  id="input-retrievaldate"
                  label="Afhaaldatum"
                  label-for="input-retrievaldate"
                  @keydown.capture.prevent.stop
                >
                  <calendar
                    v-model="retrievalDate"
                    class="w-100"
                    :disabled-days-of-week="disabled"
                    show-date-only
                    :format="format"
                    :clear-button="clear"
                    :placeholder="placeholder"
                    :disabled-days="disabledDates"
                    :min-date="minFetchDate"
                    :max-date="maxFetchDate"
                    :input-disabled="true"
                    :pane="1"
                    :position="position"
                    :lang="lang"
                    :range-bus="getBus"
                    :range-status="2"
                    :on-day-click="dayClick"
                    width="100%"
                    required
                    :disabled="waitingForProcesses"
                  ></calendar>
                </b-form-group>
              </b-col>
              <b-col xs="12" md="6">
                <b-form-group>
                  <label>Afhaaltijd</label>
                  <b-form-select
                    id="input-retrievaltime"
                    v-model="retrievalTime"
                    :options="retrievalTimes"
                    required
                    :disabled="waitingForProcesses"
                    value-field="BusinessHour"
                    disabled-field="VolZet"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12" lg="12" xl="4">
            <b-row>
              <b-col xs="12" md="6">
                <b-form-group
                  id="input-group-1"
                  label="Terugbrengdatum"
                  label-for="input-returnDate"
                >
                  <calendar
                    v-model="returnDate"
                    class="w-100"
                    :disabled-days-of-week="disabled"
                    :disabled-days="disabledDates"
                    show-date-only
                    :format="format"
                    :clear-button="clear"
                    :placeholder="placeholder"
                    :pane="1"
                    :position="position"
                    :input-disabled="true"
                    :min-date="minReturnDate"
                    :max-date="maxReturnDate"
                    :lang="lang"
                    :range-bus="getBus"
                    :range-status="2"
                    :on-day-click="dayClick"
                    width="100%"
                    required
                    :disabled="waitingForProcesses"
                  ></calendar>
                </b-form-group>
              </b-col>
              <b-col xs="12" md="6">
                <b-form-group id="input-group-returnTime">
                  <label>Terugbrengtijd</label>
                  <b-form-select
                    id="input-returntime"
                    v-model="returnTime"
                    :options="returnTimes"
                    value-field="BusinessHour"
                    disabled-field="VolZet"
                    required
                    :disabled="waitingForProcesses"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12" lg="4" xl="2">
            <div>
              <b-button
                v-if="mutationProblems.length != 0"
                v-b-modal.modal-1
                class="mt-3 btn-block"
                variant="primary"
                :disabled="isLocationOrDateChanged || waitingForProcesses"
              >
                Bijwerken
                <font-awesome-icon
                  v-if="cartProcessing"
                  class="fa-spin"
                  far
                  icon="spinner"
                />
              </b-button>

              <b-modal
                id="modal-1"
                v-model="showMutationProblems"
                :hide-header="true"
                :hide-footer="true"
              >
                <h5>
                  <span class="warning">
                    <font-awesome-icon icon="exclamation-circle" />
                  </span>
                  Opgelet!
                </h5>
                <!-- If date has changed show the following message -->
                <div v-if="!isLocationOrDateChanged">
                  <p class="my-4">
                    Als je de afhaallocatie of afhaal/terugbreng datum wijzigt,
                    heeft dit een effect op alle artikelen die al in je
                    winkelmandje zitten.
                  </p>

                  <p>
                    Onderstaand(e) artikel(en) zijn door de aanpassingen niet
                    meer beschikbaar:
                  </p>
                  <ul>
                    <li v-for="item in mutationProblems" :key="item.ID">
                      {{ item.Description }}
                    </li>
                  </ul>
                  <p>
                    Bovenstaand(e) artikel(en) zullen uit je winkelmandje worden
                    verwijderd wanneer je op "verdergaan" drukt.
                  </p>
                  <p>
                    Indien je de afhaallocatie wijzigt, zal heel je aanvraag op
                    de nieuwe locatie afgehaald moeten worden.
                  </p>
                  <p>
                    Weet je zeker dat je verder wilt gaan?
                  </p>
                  <b-button variant="primary" @click="updateCart"
                    >Verdergaan</b-button
                  >
                  <b-button
                    class="text-left"
                    variant="link"
                    @click="showMutationProblems = false"
                    >Annuleren en locatie of afhaal/terugbreng datum niet
                    aanpassen</b-button
                  >
                </div>

                <!-- Else show this message -->
                <div v-else>
                  <p class="my-4">
                    Bij een laatste controle van je winkelmandje hebben we
                    ontdekt dat niet meer alle gevraagde artikelen nog
                    beschikbaar zijn. <br />Dit wordt nu duidelijk gemaakt in
                    het winkelmandje.
                  </p>

                  <b-button
                    class="text-left"
                    variant="link"
                    @click="showMutationProblems = false"
                    >Sluiten</b-button
                  >
                </div>
              </b-modal>
            </div>
            <b-button
              v-if="mutationProblems.length == 0"
              class="mt-3 btn-block"
              variant="primary"
              :disabled="isLocationOrDateChanged"
              type="submit"
              @click="updateCart"
            >
              Bijwerken
              <font-awesome-icon
                v-if="cartProcessing || fetchingData"
                class="fa-spin"
                far
                icon="spinner"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <div>
        <b-table
          class="cart"
          responsive
          striped
          hover
          :items="items"
          :fields="fields"
        >
          <template v-slot:cell(item)="row">
            <b-row>
              <b-col>{{ row.item.item }}</b-col>
              <b-col
                v-if="
                  row.item.maxAmount < row.item.amount ||
                    row.item.maxAmount < 0
                "
                class="text-right"
              >
                <span
                  v-if="row.item.invalidCartItemReason === 'NonExistingItem'"
                  class="text-danger"
                >
                  Artikel uit het aanbod
                </span>
                <span
                  v-else-if="
                    row.item.invalidCartItemReason === 'NonExistingComposition'
                  "
                  class="text-danger"
                >
                  Artikel uit het aanbod
                </span>
                <span
                  v-else-if="
                    row.item.maxAmount < 1"
                  class="text-danger"
                  >Artikel niet meer beschikbaar</span
                >
                <span v-else class="text-danger"
                  >Gevraagd aantal niet beschikbaar, maximaal
                  {{ row.item.maxPerCustomer }} beschikbaar</span
                >
              </b-col>
            </b-row>
          </template>

          <template v-slot:cell(price)="data">
            <div class="form-group text-right price-column">
              &euro;
              {{
                (Math.round(data.item.price * 100) / 100)
                  .toFixed(2)
                  .replace('.', ',')
              }}
            </div>
          </template>

          <template v-slot:cell(amount)="row">
            <div class="form-group amount">
              <b-button
                :disabled="row.item.amount < 2"
                @click="
                  updateCartQuantity({
                    item: row.item,
                    amount: row.item.amount - 1
                  })"
                >-</b-button
              >

              <span class="pl-2 pr-2">{{ row.item.amount }}</span>

              <b-button
                :disabled="row.item.maxAmount <= row.item.amount"
                @click="
                  updateCartQuantity({
                    item: row.item,
                    amount: row.item.amount + 1
                  }) 
                "
                >+</b-button
              >
            </div>
          </template>

          <template v-slot:cell(delete)="data">
            <div class="form-group delete">
              <a href="#" @click="deleteCartItem(data.value)">Verwijder</a>
            </div>
          </template>
          <template v-slot:cell(total)="data">
            <div class="form-group text-right price-column">
              &euro;
              {{
                (Math.round(data.item.price * data.item.amount * 100) / 100)
                  .toFixed(2)
                  .replace('.', ',')
              }}
            </div>
          </template>
        </b-table>
        <div class="prices">
          <table class="table text-right">
            <tr>
              <td>
                <strong>Subtotaal:</strong>
              </td>
              <td>
                &euro;
                {{
                  totalIncVat
                    .toFixed(2)
                    .toString()
                    .replace('.', ',')
                }}
              </td>
            </tr>
            <tr v-if="adminCosts">
              <td>
                <strong>Administratiekosten:</strong>
              </td>

              <td width="100px">&euro; 10,00</td>
            </tr>
            <tr>
              <td>
                <strong>Totaalbedrag:</strong>
              </td>
              <td>
                &euro;
                {{
                  cartCache.TotalIncVAT.toFixed(2)
                    .toString()
                    .replace('.', ',')
                }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <b-card class="mb-4 mt-2">
        <b-button
          type="submit"
          class="float-right"
          variant="primary"
          :disabled="
            buttonsDisabled ||
              mutationProblems.length != 0 ||
              totalPurchaseValueExceeded || waitingForProcesses
          "
        >
          Aanvraag voltooien
          <font-awesome-icon
            v-if="cartProcessing || waitingForProcesses"
            class="fa-spin"
            far
            icon="spinner"
          />
        </b-button>
        <h2 class="pt-2">Is je winkelmandje volledig?</h2>
      </b-card>
    </b-form>
    <b-alert
      v-if="
        (this.$store.getters.cartCount < 2 && adminCosts) ||
          (!adminCosts && this.$store.getters.cartCount < 1)
      "
      show
      variant="warning"
    >
      <font-awesome-icon icon="exclamation-circle" />Je hebt nog geen artikelen
      in je winkelmandje.
    </b-alert>
  </b-container>
</template>
<script>
// @ is an alias to /src
import {
  getCart,
  deleteCartItem,
  updateCartItem,
  checkout,
  updateCart,
  cartMutationCheck
} from '@/services/CartService'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

import calendar from 'vue2-slot-calendar/lib/calendar'
import Vue from 'vue'
import Breadcrumbs from '@/components/header/BreadCrumbs'

import getPreviousWorkDay from '@/functions/rentaldates/getPreviousWorkDay'

import getMaxFetchDate from '@/functions/rentaldates/getMaxFetchDate'
import getMaxReturnDate from '@/functions/rentaldates/getMaxReturnDate'
import getMinFetchDate from '@/functions/rentaldates/getMinFetchDate'
import getMinReturnDate from '@/functions/rentaldates/getMinReturnDate'
import {retrieveCartItems} from '@/functions/cart/retrieveCartItems'

import { getTimePeriodsExact } from '@/services/TimePeriods'
import getCustClosedRentalDates from '@/services/cust/GetCustClosedRentalDates'

export default {
  name: 'Cart',
  components: {
    calendar,
    Breadcrumbs
  },
  data() {
    return {
      showMutationProblems: false,
      retrievalTimes: [],
      returnTimes: [],
      warehouses: [
        { value: 'Leuven', selected: true, text: 'Leuven' },
        { value: 'Asse', text: 'Asse' },
        { value: 'Bekkevoort', text: 'Bekkevoort' }
      ],
      warehouse: 'leuven',
      retrievalDate: '',
      retrievalTime: '',
      returnDate: '',
      returnTime: '',
      fields: [
        {
          key: 'item',
          label: 'Item'
        },
        {
          key: 'cartItemID',
          label: '',
          hidden: true,
          class: 'd-none'
        },
        {
          key: 'composition',
          class: 'd-none',
          hidden: true
        },
        {
          key: 'maxAdded',
          class: 'd-none',
          hidden: true
        },
        {
          key: 'price',
          label: 'Prijs',
          class: 'text-center'
        },
        {
          key: 'amount',
          label: 'Aantal',
          class: 'text-center'
        },
        {
          key: 'delete',
          label: ''
        },
        {
          key: 'maxPerCustomer',
          label: '',
          class: 'd-none'
        },
        {
          key: 'total',
          label: 'Totaal'
        }
      ],
      items: [],
      disabled: [0, 6],
      buttonsDisabled: true,
      position: 'left',
      timePeriods: [],
      format: 'dd/MM/yyyy',
      clear: false,
      processingTaskReturnDate: false,
      processingTaskWarehouse: false,
      lang: 'nl',
      placeholder: 'Selecteer een datum',
      bus: new Vue(),
      businessHours: {},
      mutationProblems: [],
      resetTime: false,
      cartProcessing: false,
      loading: true,
      isLocationOrDateChanged: true,
      initialLoad: false,
      disabledDates: [],
      queuedAmountUpdate: [],
      processingQuantity: false
    }
  },
  metaInfo: function() {
    return {
      title: `Winkelmandje`,
      titleTemplate: '%s - Vlaams-Brabant Uitleendienst'
    }
  },
  computed: {
    waitingForProcesses() {
      if (this.loading) return true
      if (this.processingQuantity) return true
      return false
    },
    adminCosts: function() {
      if (
        this.cartCache.CartItems.Collection.find(
          cartItem => cartItem.ItemID === 'ADMIN'
        )
      )
        return true
      return false
    },
    fetchingData() {
      if (this.processingTaskReturnDate) return true
      if (this.processingTaskWarehouse) return true
      return false
    },
    totalIncVat: function() {
      if (this.adminCosts) return this.cartCache.TotalIncVAT - 10.0
      return this.cartCache.TotalIncVAT
    },
    warning: function() {
      if (this.cartCache.TotalPurchaseValue > this.cartCache.MaxCustomerLimit)
        return 'De maximumwaarde aan materiaal dat men kan ontlenen per vereniging op elk gegeven moment is 125.000 euro. Boven deze waarde worden geen bijkomende ontleningen toegestaan.'

      return null
    },
    totalPurchaseValueExceeded: function() {
      return this.cartCache.TotalPurchaseValue > this.cartCache.MaxCustomerLimit
    },
    addedReservationItems: function() {
      return this.$route.query.addedReservationItems
    },
    paAttestRequired: function() {
      if (
        this.$store.getters.cartCache &&
        this.$store.getters.cartCache.RequiredTestimonies.length > 0
      ) {
        return (
          this.$store.getters.cartCache.RequiredTestimonies.filter(
            testimony => testimony.TestimonyNumber === 'PA Attest'
          ).length > 0
        )
      } else {
        return false
      }
    },
    riscoAnalyseRequired: function() {
      if (
        this.$store.getters.cartCache &&
        this.$store.getters.cartCache.RequiredTestimonies.length > 0
      ) {
        return (
          this.$store.getters.cartCache.RequiredTestimonies.filter(
            testimony => testimony.TestimonyNumber === 'Risico analyse'
          ).length > 0
        )
      } else {
        return false
      }
    },

    minFetchDate: function() {
      return getMinFetchDate()
    },
    maxFetchDate: function() {
      return getMaxFetchDate()
    },
    minReturnDate: function() {
      return getMinReturnDate(this.retrievalDate)
    },
    maxReturnDate: function() {
      return getMaxReturnDate(this.retrievalDate)
    },
    cartCache: function() {
      return this.$store.getters.cartCache
    },
    subTotal: function() {
      const sumPrice = this.items
        .map(item => item.total)
        .reduce((a, c) => {
          return a + c
        })

      return (Math.round(sumPrice * 100) / 100).toFixed(2).replace('.', ',')
    }
  },
  watch: {
    returnDate: async function() {
      if (this.initialLoad) return
      this.processingTaskReturnDate = true

      this.returnTime = ''

      await this.updateCartMutationProblems()
      await this.recheckAvailableHours({ resetTime: this.resetTime })

      if (
        this.returnDate.replace(/\//g, '-') !=
        this.$store.getters.DateTimeBusinessEnd
      ) {
        this.isLocationOrDateChanged = false
      }

      const returnExceedsDate = dayjs(this.returnDate, 'DD/MM/YYYY').isAfter(
        dayjs(this.maxReturnDate, 'DD/MM/YYYY')
      )

      if (returnExceedsDate) {
        this.returnDate = getPreviousWorkDay(
          dayjs(this.minReturnDate, 'DD/MM/YYYY')
        ).format('DD/MM/YYYY')
      }
      this.processingTaskReturnDate = false
    },
    retrievalDate: async function() {
      if (this.initialLoad) return
      await this.updateCartMutationProblems()
      await this.recheckAvailableHours({ resetTime: this.resetTime })
      if (
        this.retrievalDate.replace(/\//g, '-') !=
        this.$store.getters.DateTimeBusinessStart
      ) {
        this.isLocationOrDateChanged = false
      }

      this.retrievalTime = ''

      await this.getClosedRentalDates()

      const retrievalExceedsDate = dayjs(
        this.retrievalDate,
        'DD/MM/YYYY'
      ).isAfter(dayjs(this.maxFetchDate, 'DD/MM/YYYY'))

      if (retrievalExceedsDate) {
        this.retrievalDate = getPreviousWorkDay(
          dayjs(this.minFetchDate, 'DD/MM/YYYY')
        ).format('DD/MM/YYYY')
      }
    },

    warehouse: async function() {
      await this.recheckAvailableHours({
        resetTime: this.resetTime,
        updateWarehouse: false
      })
      this.retrievalTime = ''
      this.returnTime = ''
      if (this.initialLoad) return
      this.processingTaskWarehouse = true
      await this.updateCartMutationProblems()
      await this.getClosedRentalDates()

      if (this.warehouse != this.$store.getters.cartCache.WarehouseID) {
        this.isLocationOrDateChanged = false
      }

      this.processingTaskWarehouse = false
    },

    retrievalTime: async function() {
      if (this.retrievalTime != this.$store.getters.retrievalTime) {
        this.isLocationOrDateChanged = false
      }
    },
    returnTime: async function() {
      if (this.returnTime != this.$store.getters.returnTime) {
        this.isLocationOrDateChanged = false
      }
    }
  },
  async created() {
    this.initialLoad = true
    this.cartProcessing = true

    await this.refreshCart()

    await this.getClosedRentalDates()

    this.warehouse = this.cartCache.WarehouseID
    await this.updateCartMutationProblems()

    this.loading = false
    this.cartProcessing = false
    this.initialLoad = false
  },
  async mounted() {
    this.resetTime = true
  },
  methods: {
    async getClosedRentalDates() {
      const startDate = dayjs().format('YYYY-MM-DD')
      const endDate = dayjs(this.returnDate, 'DD/MM/YYYY')
        .add(6, 'months')
        .format('YYYY-MM-DD')

      const result = await getCustClosedRentalDates({
        startDate: startDate,
        endDate: endDate,
        warehouse: this.warehouse
      })

      this.disabledDates = result.map(date => {
        return dayjs(date).format('YYYY-MM-DD')
      })
    },
    async refreshCart() {
      this.loading = true
      this.cartProcessing = true

      const items = await retrieveCartItems()

      if (items === 'loggedOut') {
        this.$router.push({ name: 'index-loggout', query: { loggedOut: true } })
        return
      }

      this.items = items

      if (this.$store.getters.cartCache.DateTimeBusinessStart != null) {
        this.retrievalDate = dayjs(
          this.$store.getters.cartCache.DateTimeBusinessStart
        ).format('DD/MM/YYYY')
      }

      if (this.$store.getters.cartCache.DateTimeBusinessEnd != null) {
        this.returnDate = dayjs(
          this.$store.getters.cartCache.DateTimeBusinessEnd
        ).format('DD/MM/YYYY')
      }

      await this.recheckAvailableHours()

      this.loading = false
    },
    async deleteCartItem(cartItemId) {
      const filteredItems = this.items.filter((cartItem) => {
        return cartItem.cartItemID !== cartItemId
      })

      this.items = filteredItems

      deleteCartItem({ cartItemID: cartItemId })
            getCart()
      const count = filteredItems.length ? filteredItems.length + 1 : 0
      this.$store.commit("setCartCount", {count})
    },
    updateCartMutationProblems: async function() {
      this.buttonsDisabled = true
      this.mutationProblems = await cartMutationCheck({
        warehouseID: this.warehouse,
        dateTimeBusinessStart: this.retrievalDate,
        dateTimeBusinessEnd: this.returnDate
      })
      this.buttonsDisabled = false
    },
    hideModal: function() {
      this.showMutationProblems = false
    },
    getBus: function() {
      return this.bus
    },
    dayClick: async function() {},
    updateCartQuantity({ item, amount }) {     
      this.queuedAmountUpdate[item.cartItemID] = {cartItemID: item.cartItemID, amount}

      const items = this.items.slice()
      items.forEach((cartItem) => {
        if (cartItem.cartItemID === item.cartItemID) {
          cartItem.amount = amount
        }
      })
      this.items = items

      this.processQuantityUpdate()
    },
    async processQuantityUpdate() {
      if (this.processingQuantity) {
        return
      }
      this.processingQuantity = true

      while (Object.keys(this.queuedAmountUpdate)) {
        const key = Object.keys(this.queuedAmountUpdate)[0]
        if (!key) break
        
        const {cartItemID, amount} = this.queuedAmountUpdate[key]
        
        delete this.queuedAmountUpdate[key]

        await updateCartItem({cartItemID, amount})
        await this.updateCartMutationProblems()
      }

      this.processingQuantity = false
    },
    async updateCart(event) {
      event.preventDefault()
      this.cartProcessing = true

      if (
        dayjs(this.retrievalDate, 'DD-MM-YYYY').format('X') >
        dayjs(this.returnDate, 'DD-MM-YYYY').format('X')
      ) {
        this.cartProcessing = false
      } else {
        await updateCart({
          warehouseID: this.warehouse,
          businessHourStart: this.retrievalTime,
          businessHourEnd: this.returnTime,
          dateTimeBusinessStart: this.retrievalDate,
          dateTimeBusinessEnd: this.returnDate
        })
      }
      this.showMutationProblems = false
      this.$store.commit('setReturnTime', { returnTime: this.returnTime })
      this.$store.commit('setRetrievalTime', {
        retrievalTime: this.retrievalTime
      })

      await this.refreshCart()

      this.cartProcessing = false
      this.isLocationOrDateChanged = true
    },
    async updateCartAndContinue(event) {
      event.preventDefault()
      this.cartProcessing = true

      await this.updateCartMutationProblems()

      if (this.mutationProblems.length > 0) {
        this.showMutationProblems = true

        this.items  = await retrieveCartItems()
       
        this.cartProcessing = false
        return
      }

      if (
        dayjs(this.retrievalDate, 'DD-MM-YYYY').format('X') >
        dayjs(this.returnDate, 'DD-MM-YYYY').format('X')
      ) {
        this.cartProcessing = false
        await alert('De afhaaldatum is later dan de terugbrengdatum')
        return
      } else {
        await updateCart({
          warehouseID: this.warehouse,
          businessHourStart: this.retrievalTime,
          businessHourEnd: this.returnTime,
          dateTimeBusinessStart: this.retrievalDate,
          dateTimeBusinessEnd: this.returnDate
        })

        this.$router.push({ name: 'confirmation' })
      }
    },
    recheckAvailableHours: async function({ updateWarehouse = true } = {}) {
      if (updateWarehouse) {
        this.warehouse = this.cartCache.WarehouseID
      }

      const retrievalTimePeriods = await getTimePeriodsExact({
        startDate: dayjs(this.retrievalDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        endDate: dayjs(this.retrievalDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        warehouseID: this.warehouse
      })

      const returnTimePeriods = await getTimePeriodsExact({
        startDate: dayjs(this.returnDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        endDate: dayjs(this.returnDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        warehouseID: this.warehouse
      })

      this.retrievalTimes = retrievalTimePeriods.Collection.filter(
        timePeriod => timePeriod.CUST_VisibleInFront === true
      )

      this.returnTimes = returnTimePeriods.Collection.filter(
        timePeriod => timePeriod.CUST_VisibleInFront === true
      )

      for (let businessHour of this.retrievalTimes) {
        if (
          businessHour.value == this.$store.getters.cartCache.BusinessHourStart
        ) {
          businessHour.selected = true
        }
      }

      this.retrievalTimes.forEach(timePeriod => {
        timePeriod.text = timePeriod.VolZet
          ? `${timePeriod.BusinessHour} VOLZET`
          : timePeriod.BusinessHour
      })

      this.returnTimes.forEach(timePeriod => {
        timePeriod.text = timePeriod.VolZet
          ? `${timePeriod.BusinessHour} VOLZET`
          : timePeriod.BusinessHour
      })
    },
    checkout: async function() {
      const reservation = await checkout()
      this.items = await retrieveCartItems()
      this.$store.commit('setReservation', {
        reservation: { reservation, items: this.items }
      })
    },
    addWeekdays(date, days) {
      date = dayjs(date) // use a clone
      while (days > 0) {
        date = date.add(1, 'days')
        // decrease "days" only if it's a weekday.
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
          days -= 1
        }
      }
      return date
    }
  }
}
</script>
<style lang="scss">
.label-padding {
  margin-top: 35px;
}
.cart-total-price {
  p {
    font-size: 18px;
    font-weight: 600;
    font-family: 'DecimaPro-bold' !important;
  }
}

:disabled {
  color: #c5c5c5 !important;
}
.datepicker-input {
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  height: 38px;
}
.modal-content {
  border: 5px solid #d3732f;
  border-radius: 5px;
}
.warning {
  color: #d3732f;
}
.card {
  font-size: 18px;
  label {
    font-family: 'DecimaPro-bold' !important;
  }
}
.table {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border: none;

  border-collapse: separate;
  border-spacing: 0;

  background-color: white;

  thead {
    border-radius: 0;
  }
  tbody {
    .price-column {
      white-space: nowrap;
    }
    td {
      padding-top: 1rem;
      padding-bottom: 1rem;
      background-color: white !important;
      vertical-align: baseline;
    }
  }
}
.disabled {
  cursor: not-allowed;
}
.amount {
  min-width: 110px;
  .btn {
    border: 1px solid #efefef;
    background-color: white;
    color: black;
    height: 41px;
    width: 41px;
    line-height: 0.5px;
  }
}
.prices {
  padding-right: 0px;
  .table {
    background-color: transparent;
    tr,
    td {
      border: none !important;
      font-size: 16px;
      strong {
        font-family: 'DecimaPro-Bold';
      }
    }
  }
}
.price-values {
  p {
    padding-bottom: 3px !important;
  }
}
select,
input {
  height: 40px !important;
}

.delete {
  a {
    font-family: 'DecimaPro-bold' !important;
  }
}
.no-edit {
  pointer-events: none;
}
</style>
