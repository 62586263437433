import dayjs from 'dayjs'

export default function getPreviousWorkDay(dayjsObject, disabledDates) {
  let previousWorkDay = dayjsObject.subtract(1, 'days')

  for (let day = previousWorkDay.day(); day > 5; day--) {
    previousWorkDay = previousWorkDay.subtract(1, 'days')
  }

  if (disabledDates.includes(dayjs(previousWorkDay).format('YYYY-MM-DD'))) {
    while (
      disabledDates.includes(dayjs(previousWorkDay).format('YYYY-MM-DD'))
    ) {
      previousWorkDay = previousWorkDay.subtract(1, 'days').format('YYYY-MM-DD')
    }
  }

  return previousWorkDay
}
